/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

import template from './url-targeting.html';
import {ADN_TARGETING_TYPES} from "../targeting-constants";

const MODULE_NAME = 'url-targeting-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnUrlTargeting', function() {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnUrlTargeting',
        underModel: '=',
        isLockedDown: '<',
        overridableTargeting: '<',
        mode: '@'
      },
      link: {
        post: function(scope, element, attrs, tabset) {
          scope.loaded = true;
          scope.targetType = ADN_TARGETING_TYPES.url;
          let init = function() {
            let inputted = [];
            _.forEach(_.get(scope.model, 'urls'), function(addy) {
              inputted.push(addy.indexOf(",") > -1 ? "\"" + addy + "\"" : addy);
            });
            scope.inputted = {value: inputted.join(", ")};
          };
          init();

          let prepareForSave = function() {
            let inputtedValue = scope.inputted.value;
            const doubleQuoteIndices = [];
            let index = inputtedValue.indexOf("\"");
            while (index >= 0) {
              doubleQuoteIndices.push(index);
              index = inputtedValue.indexOf("\"", index + 1);
            }
            if (doubleQuoteIndices.length % 2 === 0) {
              const portions = [];
              for (let i = 0; i < doubleQuoteIndices.length; i = i + 2) {
                portions.push(inputtedValue.substring(doubleQuoteIndices[i] + 1, doubleQuoteIndices[i + 1]));
              }
              _.forEach(portions, function(portion) {
                inputtedValue = inputtedValue.replace("\"" + portion + "\"", portion.replaceAll(",", "THIS=IS=COMMA"));
              });
            }
            let values = inputtedValue.split(",");
            let trimmedValues = _.filter(_.map(values, function(v) {
              return _.trim(v.replaceAll("THIS=IS=COMMA", ","));
            }), function(v) {
              return _.isString(v) && v.length > 0 && v.indexOf(" ") < 0 && v.indexOf("\"") < 0;
            });

            scope.model = scope.model || {urls: []};
            scope.model.urls = trimmedValues;
            init();
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            prepareForSave();
            var summary = (scope.model.negated === true ? "NOT: " : "") + scope.model.urls.join(", ");
            return {
              vm: angular.copy(scope.inputted),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.url,
              summary: summary
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            init();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.ipAddress.widget, prepareForSave, copyTemplateFunc);
          tabset.callBeforeSubmit(prepareForSave);
          tabset.registerAllTabs(prepareForSave);
        }
      }
    };
  });

export default MODULE_NAME;